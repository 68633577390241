//Gallery.js
import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

// Sample image data
const imageData = [
  { url: '/images/services/plantering.jpg', alt: 'Planting services' },
  { url: '/images/services/landscaping.jpg', alt: 'Landscaping services' },
  { url: '/images/services/sandsopning.jpg', alt: 'Sand sweeping services' },
  { url: '/images/services/snickare.jpg', alt: 'Carpentry services' },
  { url: '/images/services/stensattning.jpg', alt: 'Stone setting services' },
  { url: '/images/services/tradskotsel.jpg', alt: 'Tree care services' },
  { url: '/images/services/treeservice.jpg', alt: 'Tree service' },
  { url: '/images/services/vinterunderhall.jpg', alt: 'Winter maintenance services' },
];

const Gallery = () => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setSelectedImageIndex(prevIndex => (prevIndex + 1) % imageData.length);
    }, 3000);
    return () => clearInterval(timer);
  }, []);

  return (
    <Container className="mt-5 text-center">
      <Row className="mb-4">
        <Col md={12}>
          <img
            src={imageData[selectedImageIndex].url}
            alt={imageData[selectedImageIndex].alt}
            className="img-fluid img-thumbnail"
            style={{ transition: 'opacity 0.5s ease', boxShadow: '0 0 8px rgba(0, 0, 0, 0.6)', width: '45%' }}
          />
        </Col>
      </Row>
      <Row>
        {imageData.map((image, index) => (
          <Col xs={6} sm={4} md={3} lg={2} key={index} className="mb-2">
            <img
              src={image.url}
              alt={image.alt}
              className="img-fluid img-thumbnail"
              onClick={() => setSelectedImageIndex(index)}
              style={{
                cursor: 'pointer',
                opacity: selectedImageIndex === index ? 0.5 : 1,
                transition: 'opacity 0.3s ease',
                boxShadow: selectedImageIndex === index ? '0 0 10px rgba(0, 123, 255, 0.5)' : 'none'
              }}
              aria-label={`View image ${index + 1}`}
            />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Gallery;