//AboutPage
import React from 'react';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function AboutPage() {
  return (
    <Container className="my-5">
      <h2 className="text-center mb-4 bw-bold">Om Oss</h2>
      <Row>
        <Col md={6}>
          <p>Spånga Trädgård AB är ett familjeägt trädgårdsföretag som grundades 2012. Vi är specialiserade på skötsel och förbättring av utomhusmiljöer, och vårt arbetsområde täcker Stockholm och dess omgivningar. Vårt erbjudande omfattar allt från anläggningsarbeten och stenläggning till snöskottning och trädfällning, där varje tjänst är anpassad efter våra kunders unika behov.</p>
          <p>Oavsett om du är en privatperson eller representerar ett företag, och oavsett projektets storlek, har vi kompetensen att ta hand om din utemiljö med högsta möjliga standard. Vi tar oss an allt från mindre trädgårdsprojekt till större landskapsdesigner, och strävar alltid efter att överträffa våra kunders förväntningar.</p>
          <p><strong>Varför välja oss?</strong></p>
          <ul>
            <li><strong>Familjeägt företag:</strong> Vi bryr oss om varje projekt som om det vore vårt eget, vilket garanterar personligt engagemang och kvalitet.</li>
            <li><strong>Brett tjänsteutbud:</strong> Från plantering och stenarbeten till vintersäkring och regelbunden underhåll.</li>
            <li><strong>Alla kundtyper:</strong> Vi välkomnar både små och stora uppdrag från både privatpersoner och företag.</li>
          </ul>
          <p>För mer information eller för att begära en offert, <Link to="/contact">kontakta oss</Link> idag!</p>
        </Col>
        <Col md={6}>
        <Image  src="/logo5125.png" height="300" className="mb-4" />

          
        </Col>
      </Row>
      <Row className="mt-4">
        <Col className="text-center">
          <Button variant="success" as={Link} to="/contact">Kontakta Oss</Button>
        </Col>
      </Row>
    </Container>
  );
}

export default AboutPage;