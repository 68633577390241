// ForgotPasswordForm.js
import React, { useState } from 'react';
import { Form, Button, Alert, Container, Row, Col } from 'react-bootstrap';

function ForgotPasswordForm() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    try {
      const response = await fetch('https://backend-spanga.onrender.com/api/forgot-password', {
        // const response = await fetch('http://localhost:3001/api/forgot-password', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setMessage('En länk för att återställa lösenordet har skickats till din e-postadress.');
        //send the user to the login page
        setTimeout(() => {
          window.location.href = '/login';
        }, 5000);
      } else {
        setError('Misslyckades med att skicka länken för återställning av lösenord. Försök igen.');
      }
    } catch (error) {
      setError('Ett oväntat fel inträffade. Försök igen.');
    }
  };

  return (
    <section className="py-5 h-100" style={{ backgroundColor: '#fff' }}>
      <Container className="py-5 h-100">
        <Row className="d-flex justify-content-center align-items-center h-100">
          <Col md={8} lg={6} xl={5}>
            <div className="card shadow-sm bg-light" style={{ borderRadius: "1rem" }}>
              <div className="card-body p-4 p-md-5 text-center">

                <h2 className="fw-bold mb-2 text-uppercase">Glömt Lösenord</h2>
                <p className="text-black-50 mb-5">Ange din e-postadress för att återställa ditt lösenord!</p>

                <Form onSubmit={handleSubmit}>
                <Form.Label style={{ textAlign: 'left', width: '100%' }}>Email</Form.Label>
                  <Form.Group className="form-outline mb-4">
                    <Form.Control
                      type="email"
                      placeholder="Din e-postadress"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </Form.Group>

                  <Button variant="primary" className="btn btn-success btn-lg btn-block mb-3" type="submit">Skicka Återställningslänk</Button>
                </Form>

                {message && <Alert variant="success">{message}</Alert>}
                {error && <Alert variant="danger">{error}</Alert>}

                <p className="small mb-4">
                  <a href="/login" className="text-blue">Tillbaka till inloggning</a>
                </p>

              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default ForgotPasswordForm;