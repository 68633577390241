//UserProfile.js
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Alert, Form, Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './UserProfile_CSS.css'; // Import CSS file

function UserProfile({ userName, userId }) {
  const navigate = useNavigate();

  // State to hold user data
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    personnummer: '',
    gatuadress: '',
    postnr: '',
    stad: '',
  });

  // State to hold initial user data for comparison
  const [initialUserData, setInitialUserData] = useState({});

  // State to manage errors and alerts
  const [error, setError] = useState('');
  const [alert, setAlert] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);

  // Fetch user data when the component mounts or userId changes
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // Fetch user data from the API
        const response = await fetch(`https://backend-spanga.onrender.com/api/messages?userId=${userId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch user data.');
        }
        const data = await response.json();
        
        // Update userData and initialUserData states
        setUserData({
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          phoneNumber: data.phoneNumber,
          personnummer: data.personnummer,
          gatuadress: data.gatuadress,
          postnr: data.postnr,
          stad: data.stad,
        });
        setInitialUserData({
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          phoneNumber: data.phoneNumber,
          personnummer: data.personnummer,
          gatuadress: data.gatuadress,
          postnr: data.postnr,
          stad: data.stad,
        });
      } catch (err) {
        setError(err.message);
      }
    };

    if (userId) {
      fetchUserData();
    }
  }, [userId]);

  // Handle changes to the form inputs
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData(prevData => ({ ...prevData, [name]: value }));
  };

  // Handle save button click
  const handleSave = async () => {
    // Check if there are changes before sending request to the server
    if (JSON.stringify(userData) === JSON.stringify(initialUserData)) {
      setShowConfirm(false);
      return; // No changes made, do not send request to DB
    }

    try {
      // Send updated user data to the server
      await axios.post(`https://backend-spanga.onrender.com/api/updateUser`, { userId, ...userData });
      setAlert('Din data sparat');
      
      // Update initialUserData to the latest userData
      setInitialUserData(userData);
    } catch (error) {
      console.error('Failed to save data:', error);
    } finally {
      setShowConfirm(false);
    }
  };

  // Handle cancel button click
  const handleCancel = () => {
    navigate('/profile');
  };

  // Handle confirm save button click
  const handleConfirmSave = () => {
    setShowConfirm(true);
  };

  // Handle close confirm modal
  const handleConfirmClose = () => {
    setShowConfirm(false);
  };

  return (
    <Container className="mt-4">
      {/* Navigation Bar */}
      <nav className="nav nav-borders">
        <button className="nav-link active ms-0 btn-link" onClick={() => navigate('/profile')}>Profil</button>
        {/* <button className="nav-link btn-link" onClick={() => navigate('/billing')}>Fakturering</button> */}
        {/* <button className="nav-link btn-link" onClick={() => navigate('/security')}>Säkerhet</button> */}
        {/* <button className="nav-link btn-link" onClick={() => navigate('/notifications')}>Aviseringar</button> */}
      </nav>
      <hr className="mt-0 mb-4" />

      {/* Alert Messages */}
      {alert && <Alert variant="success">{alert}</Alert>}
      {error && <Alert variant="danger">{error}</Alert>}

      {/* User Details Form */}
      <Row className="justify-content-center">
        <Col xl={8}>
          <Card className="mb-4">
            <Card.Header>Kontouppgifter</Card.Header>
            <Card.Body>
              <Form>
                <Row className="gx-3 mb-3">
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label className="small mb-1">Förnamn</Form.Label>
                      <Form.Control
                        type="text"
                        name="firstName"
                        value={userData.firstName}
                        onChange={handleChange}
                        placeholder="Ange ditt förnamn"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label className="small mb-1">Efternamn</Form.Label>
                      <Form.Control
                        type="text"
                        name="lastName"
                        value={userData.lastName}
                        onChange={handleChange}
                        placeholder="Ange ditt efternamn"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group className="mb-3">
                  <Form.Label className="small mb-1">Personnummer</Form.Label>
                  <Form.Control
                    type="text"
                    name="personnummer"
                    value={userData.personnummer}
                    onChange={handleChange}
                    placeholder="ÅÅÅÅMMDD-YYYY"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="small mb-1">E-postadress</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={userData.email}
                    onChange={handleChange}
                    placeholder="Ange din e-postadress"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="small mb-1">Telefonnummer</Form.Label>
                  <Form.Control
                    type="tel"
                    name="phoneNumber"
                    value={userData.phoneNumber}
                    onChange={handleChange}
                    placeholder="Ange ditt telefonnummer"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="small mb-1">Gatuadress</Form.Label>
                  <Form.Control
                    type="text"
                    name="gatuadress"
                    value={userData.gatuadress}
                    onChange={handleChange}
                    placeholder="Ange din gatuadress"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="small mb-1">Postnummer</Form.Label>
                  <Form.Control
                    type="text"
                    name="postnr"
                    value={userData.postnr}
                    onChange={handleChange}
                    placeholder="Ange ditt postnummer"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="small mb-1">Stad</Form.Label>
                  <Form.Control
                    type="text"
                    name="stad"
                    value={userData.stad}
                    onChange={handleChange}
                    placeholder="Ange din stad"
                  />
                </Form.Group>
                <Button className="btn-primary" type="button" onClick={handleConfirmSave}>Spara ändring</Button>
                <Button className="btn-secondary ms-2" type="button" onClick={handleCancel}>Avbryta</Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Confirm Save Modal */}
      <Modal show={showConfirm} onHide={handleConfirmClose}>
        <Modal.Header closeButton>
          <Modal.Title>Bekräfta ändringar</Modal.Title>
        </Modal.Header>
        <Modal.Body>Är du säker på att du vill spara ändringarna?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleConfirmClose}>
            Avbryt
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Spara ändringar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default UserProfile;