// // Login.js
// import React, { useState } from 'react';
// // import axios from 'axios';

// import { useNavigate } from 'react-router-dom';
// import { Alert, Button, Form } from 'react-bootstrap';


// const Login = ({ onLogin }) => {
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const [error, setError] = useState('');
//   const navigate = useNavigate();

//   const axios = require('axios');

//   const handleLogin = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await axios.post('/api/login', { email, password });
//       const { token, userName, userId } = response.data;
//       onLogin(userName, token, userId); // Anropa App.js loginHandle
//       navigate('/profile'); // Navigate to profile after login
//     } catch (err) {
//       setError('Inloggning misslyckades. Kontrollera dina uppgifter och försök igen.');
//     }
//   };


//   return (
//     // <GoogleOAuthProvider clientId="510751639210-npk6khe8io14llamgfvtj4abbnotsr5k.apps.googleusercontent.com">
//     <section className="py-5 h-100" style={{ backgroundColor: '#fff' }}>
//       <div className="container py-5 h-100">
//         <div className="row d-flex justify-content-center align-items-center h-100">
//           <div className="col-12 col-md-8 col-lg-6 col-xl-5">
//             <div className="card shadow-sm bg-light" style={{ borderRadius: "1rem" }}>
//               <div className="card-body p-4 p-md-5 text-center">
//                 <h2 className="fw-bold mb-3 mb-md-4 text-uppercase">Login</h2>
//                 {error && <Alert variant="danger">{error}</Alert>}
//                 <Form onSubmit={handleLogin}>
//                   <Form.Group className="form-outline mb-4">
//                     <Form.Control type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
//                     <Form.Label>Email</Form.Label>
//                   </Form.Group>
//                   <Form.Group className="form-outline mb-4">
//                     <Form.Control type="password" placeholder="Lösenord" value={password} onChange={(e) => setPassword(e.target.value)} required />
//                     <Form.Label>Lösenord</Form.Label>
//                   </Form.Group>
//                   <p className="small mb-4"><a href="/forgot-password" className="text-blue">Glömt lösenord?</a></p>
//                   <Button className="btn btn-success btn-lg btn-block mb-3" type="submit">Logga in</Button>

//                   <p className="mb-0">Har du inget konto? <a href="/signup" className="text-blue fw-bold">Registrera dig</a></p>
//                 </Form>


//               </div>

//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default Login;

import React, { useState } from 'react';
import axios from 'axios'; // Import axios using ES module syntax
import { useNavigate } from 'react-router-dom';
import { Alert, Button, Form } from 'react-bootstrap';

const Login = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://backend-spanga.onrender.com/api/login', { email, password });
      // const response = await axios.post('http://localhost:3001/api/login', { email, password });
      const { token, userName, userId } = response.data;
      onLogin(userName, token, userId); // Call App.js loginHandle
      navigate('/profile'); // Navigate to profile after login
    } catch (err) {
      setError('Inloggning misslyckades. Kontrollera dina uppgifter och försök igen.');
    }
  };

  return (
    <section className="py-5 h-100" style={{ backgroundColor: '#fff' }}>
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-12 col-md-8 col-lg-6 col-xl-5">
            <div className="card shadow-sm bg-light" style={{ borderRadius: "1rem" }}>
              <div className="card-body p-4 p-md-5 text-center">
                <h2 className="fw-bold mb-3 mb-md-4 text-uppercase">Login</h2>
                {error && <Alert variant="danger">{error}</Alert>}
                <Form onSubmit={handleLogin}>
                  <Form.Group className="form-outline mb-4">
                  <Form.Label style={{ textAlign: 'left', width: '100%' }}>Email</Form.Label>
                    <Form.Control type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                  </Form.Group>
                  <Form.Group className="form-outline mb-4">
                  <Form.Label style={{ textAlign: 'left', width: '100%' }}>Lösenord</Form.Label>
                    <Form.Control type="password" placeholder="Lösenord" value={password} onChange={(e) => setPassword(e.target.value)} required />
                  </Form.Group>
                  <p className="small mb-4"><a href="/forgot-password" className="text-blue">Glömt lösenord?</a></p>
                  <Button className="btn btn-success btn-lg btn-block mb-3" type="submit">Logga in</Button>
                  <p className="mb-0">Har du inget konto? <a href="/signup" className="text-blue fw-bold">Registrera dig</a></p>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
