// //index.js
// import React from 'react';
// import ReactDOM from 'react-dom/client';
// // import './index.css';
// import App from './App.js';
// import reportWebVitals from './reportWebVitals';
// import 'bootstrap/dist/css/bootstrap.min.css';

// // import 'mdb-react-ui-kit/dist/css/mdb.min.css';

// // import 'bootstrap/dist/css/bootstrap.min.css';


// import { BrowserRouter } from 'react-router-dom'; // Importera BrowserRouter

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <BrowserRouter> {/* Omslut din App-komponent med BrowserRouter */}
//       <App />
//     </BrowserRouter>
//   </React.StrictMode>
// );



// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import App from './App.js'; // Added .js extension
import reportWebVitals from './reportWebVitals.js'; // Added .js extension
import 'bootstrap/dist/css/bootstrap.min.css';

// import 'mdb-react-ui-kit/dist/css/mdb.min.css';

// import 'bootstrap/dist/css/bootstrap.min.css';

import { BrowserRouter } from 'react-router-dom'; // Importera BrowserRouter

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter> {/* Omslut din App-komponent med BrowserRouter */}
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals.console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
