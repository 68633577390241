
//Signup.js
import React, { useState } from 'react';
import { Form, Button, Alert, Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'; // Importera useNavigate från 'react-router-dom'


function Signup({ onChangeView }) {
  const [formData, setFormData] = useState({
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    confirmPassword: '' // Bekräfta lösenord
  });

  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate(); // Skapa en instans av useNavigate hook


  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setError('');
    setSuccessMessage('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      setError('Lösenorden matchar inte.');
      return;
    }

    try {
      const response = await fetch('https://backend-spanga.onrender.com/api/register', {
        // const response = await fetch('http://localhost:3001/api/register', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorMessage = await response.text();
        setError(errorMessage);
      } else {
        setSuccessMessage('Registreringen lyckades!');
        setTimeout(() => navigate('/login'), 2000); // Använd navigate för att omdirigera till login efter en viss timeout
      }
    } catch (error) {
      setError('Ett oväntat fel uppstod. Försök igen.');
    }
  };

  return (
    <section className="py-5 h-100" style={{ backgroundColor: '#fff' }}>
      <Container className="py-5 h-100" >
        <Row className="d-flex justify-content-center align-items-center h-100">
          <Col md={8} lg={6} xl={5}>
            <div className="card shadow-sm bg-light" style={{ borderRadius: "1rem" }}>
              <div className="card-body p-4 p-md-5 text-center">

                <h2 className="fw-bold mb-2 text-uppercase">Registrera</h2>
                <p className="text-black-50 mb-5">Fyll i dina uppgifter för att skapa ett konto!</p>

                <Form onSubmit={handleSubmit}>
                  <Form.Group className="form-outline mb-4">
                  <Form.Label style={{ textAlign: 'left', width: '100%' }}>E-postadress</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder="E-post"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>

                  <Form.Group className="form-outline mb-4">
                  <Form.Label style={{ textAlign: 'left', width: '100%' }}>Förnamn</Form.Label>
                    <Form.Control
                      type="text"
                      name="firstName"
                      placeholder="Förnamn"
                      value={formData.firstName}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>

                  <Form.Group className="form-outline mb-4">
                  <Form.Label style={{ textAlign: 'left', width: '100%' }}>Efternamn</Form.Label>
                    <Form.Control
                      type="text"
                      name="lastName"
                      placeholder="Efternamn"
                      value={formData.lastName}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>

                  <Form.Group className="form-outline mb-4">
                  <Form.Label style={{ textAlign: 'left', width: '100%' }}>Lösenord</Form.Label>
                    <Form.Control
                      type="password"
                      name="password"
                      placeholder="Lösenord"
                      value={formData.password}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>

                  <Form.Group className="form-outline mb-4">
                  <Form.Label style={{ textAlign: 'left', width: '100%' }}>Bekräfta lösenord</Form.Label>
                    <Form.Control
                      type="password"
                      name="confirmPassword"
                      placeholder="Bekräfta lösenord"
                      value={formData.confirmPassword}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>

                  {error && <Alert variant="danger">{error}</Alert>}
                  {successMessage && <Alert variant="success">{successMessage}</Alert>}

                  <Button variant="success" className="btn-lg btn-block mb-3" type="submit">Registrera</Button>
                </Form>
                {/* <Button variant="link" onClick={() => onChangeView('login')} className="text-blue fw-bold p-0 border-0 bg-transparent">Logga in</Button> */}
                {/* <Form.Group className="text-center mt-3">
                <Form.Text>
                    Har du redan ett konto?{' '}
                    <Button variant="link" onClick={() => onChangeView('login')} className="text-blue fw-bold p-0 border-0 bg-transparent">Logga in</Button>
                </Form.Text>
                </Form.Group> */}

                <p className="mb-0">Har du redan ett konto? <a href="/login" onClick={() => onChangeView('login')} className="text-blue fw-bold">Logga in</a></p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Signup;