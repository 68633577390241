//Kontakta.js //V-1 with out API call
// import React, { useState } from 'react';
// import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
// import { GeoAlt, Telephone, Envelope } from 'react-bootstrap-icons';
// import ReCAPTCHA from 'react-google-recaptcha';

// function Kontakta() {
//   const [formData, setFormData] = useState({
//     email: '',
//     firstName: '',
//     lastName: '',

//     phoneNumber: '',
//     gatuadress: '',
//     postnr:'',
//     stad:'',

//     message: '',
//     consentGiven: false,
//     recaptchaToken: '',  // This will hold the ReCAPTCHA response token
//     service: '' // Lägg till detta för att hantera vald tjänst
//   });
//   const [validated, setValidated] = useState(false);
//   const [feedbackMessage, setFeedbackMessage] = useState('');
//   const [feedbackType, setFeedbackType] = useState('');

//     // Tjänster
//     const services = [
//       "Trädgårdsskötsel", "Gräsklippning", "Häckklippning", "Trädbeskärning", "Ogräsrensning", "Buskbeskärning", "Höststädning", "Bortforsling av trädgårdsavfall", 
//       "Maskinell snöröjning", "Manuell snöröjning", "Maskinell halkbekämpning", "Manuell halkbekämpning", "Sandsopning",
//       "Anläggning av nya trädgårdar", "Stenläggning och Stensättning", "Planteringsarbeten", "Bevattningssystem", "Mursättning", "Anlägga gräsmatta",
//       "Fruktträdsbeskärning", "Gödsling", "Sandning", "Snöröjning och halkbekämpning",
//       "Snickeriarbeten", "Mark- och renhållning", "Trädfällning", "Installation och underhåll av uteplatser och trädgårdsmöbler", "Övriga ..."
//     ];

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const form = e.currentTarget;
//     if (!form.checkValidity() || !formData.consentGiven || !formData.recaptchaToken) {
//       console.error('Form validation failed:', { valid: form.checkValidity(), consent: formData.consentGiven, captcha: formData.recaptchaToken });

//       e.stopPropagation();
//       setValidated(true);
//       setFeedbackMessage('Vänligen se till att alla fält är ifyllda korrekt och att ReCAPTCHA är komplett.');
//       setFeedbackType('danger');
//       return;
//     }

//     try {
//       console.log('Sending data to server:', formData);
//       const response = await fetch('https://backend-spanga.onrender.com/api/contact', {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(formData),
//       });

//       if (response.ok) {
//         setFeedbackMessage('Ditt meddelande har skickats till Spånga Trädgård AB.');
//         setFeedbackType('success');
//         setFormData({ email: '', firstName: '', lastName: '', phoneNumber: '', gatuadress: '', postnr: '', stad: '', message: '', consentGiven: false, recaptchaToken: '', service: '' });
//         setValidated(false);
//       } else {
//         throw new Error('Serverfel inträffade. Försök igen senare.');
//       }
//     } catch (error) {
//       console.error('Error sending contact form email:', error);
//       setFeedbackMessage(error.message || 'Det gick inte att skicka ditt meddelande.');
//       setFeedbackType('danger');
//     }
//   };

//   const handleChange = (e) => {
//     const { name, value, checked, type } = e.target;
//     setFormData({ ...formData, [name]: type === 'checkbox' ? checked : value });
//   };

//   const onCaptchaChange = (value) => {
//     console.log("Captcha value:", value);
//     setFormData({ ...formData, recaptchaToken: value });
//   };

//   return (
//     <Container className="my-5 bg-light">
//       <h2 className="text-center mb-4 fw-bold">Kontakta Oss</h2>
//       <p className="text-center mb-4">Har du frågor? Tveka inte att kontakta oss.</p>
//       {feedbackMessage && <Alert variant={feedbackType}>{feedbackMessage}</Alert>}
//       <Form noValidate validated={validated} onSubmit={handleSubmit}>
//         <Row>
//           <Col md={6}>

//           <div className="row">
//             <Form.Group className="mb-3 col-md-6" controlId="formBasicFirstName">
//               <Form.Label >Förnamn</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Ditt förnamn"
//                 name="firstName"
//                 value={formData.firstName}
//                 onChange={handleChange}
//                 required
//               />
//             </Form.Group>
//             <Form.Group className="mb-3 col-md-6" controlId="formBasicLastName">
//               <Form.Label>Efternamn</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Ditt efternamn"
//                 name="lastName"
//                 value={formData.lastName}
//                 onChange={handleChange}
//                 required
//               />
//             </Form.Group>
//             </div>

//             <div className='row'>
//             <Form.Group className="mb-3 col-md-6" controlId="formBasicEmail">
//               <Form.Label>Email</Form.Label>
//               <Form.Control
//                 type="email"
//                 placeholder="Din email"
//                 name="email"
//                 value={formData.email}
//                 onChange={handleChange}
//                 required
//               />
//             </Form.Group>

//             <Form.Group className="mb-3 col-md-6" controlId="formBasiccPhoneNumber">
//               <Form.Label>Telefonnummer</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Ditt Telefonnummer"
//                 name="phoneNumber"
//                 value={formData.phoneNumber}
//                 onChange={handleChange}
//                 required
//               />
//             </Form.Group>
//             </div>



//             <Form.Group className="mb-3" controlId="formBasicGatuadress">
//               <Form.Label>Gatuadress</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Ditt Gatuadress"
//                 name="gatuadress"
//                 value={formData.gatuadress}
//                 onChange={handleChange}
//                 required
//               />
//             </Form.Group>

//               {/* Grupp för Postnummer och Stad på samma rad */}
//               <div className="row">
//                 <Form.Group className="mb-3 col-md-6" controlId="formBasicPostnr">
//                   <Form.Label>Postnummer</Form.Label>
//                   <Form.Control
//                     type="text"
//                     placeholder="Ditt Postnummer"
//                     name="postnr"
//                     value={formData.postnr}
//                     onChange={handleChange}
//                     required
//                   />
//                 </Form.Group>

//                 <Form.Group className="mb-3 col-md-6" controlId="formBasicStad">
//                   <Form.Label>Stad</Form.Label>
//                   <Form.Control
//                     type="text"
//                     placeholder="Ditt Stad"
//                     name="stad"
//                     value={formData.stad}
//                     onChange={handleChange}
//                     required
//                   />
//                 </Form.Group>
//               </div>



//             {/* Lägg till dropdown-listan här */}
//             <Form.Group className="mb-3" controlId="formBasicService">
//               <Form.Label>Välj tjänst</Form.Label>
//               <Form.Control as="select" name="service" value={formData.service} onChange={handleChange} required>
//                 <option value="">Välj en tjänst...</option>
//                 {services.map((service, index) => (
//                   <option key={index} value={service}>{service}</option>
//                 ))}
//               </Form.Control>
//             </Form.Group>






//             <Form.Group className="mb-3" controlId="formBasicMessage">
//               <Form.Label>Meddelande</Form.Label>
//               <Form.Control
//                 as="textarea"
//                 rows={3}
//                 placeholder="Ditt meddelande"
//                 name="message"
//                 value={formData.message}
//                 onChange={handleChange}
//                 required
//               />
//             </Form.Group>
//             <Form.Group className="mb-3" controlId="formBasicCheckbox">
//               <Form.Check
//                 type="checkbox"
//                 label="Jag samtycker till Integritetspolicyn."
//                 name="consentGiven"
//                 checked={formData.consentGiven}
//                 onChange={handleChange}
//                 required
//               />
//               <a href="/PrivacyPolicy" target="_blank">Läs mer</a>
//             </Form.Group>
//             <ReCAPTCHA
//               sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}

//               onChange={onCaptchaChange}
//             />
//             <Button variant="success" type="submit" disabled={!formData.consentGiven || !formData.recaptchaToken}>Skicka</Button>
//           </Col>
//           <Col md={6} sm={12} className='mt-4'>
//             {/* Responsive map container */}
//             <div>
//               <p>
//                 <GeoAlt className="text-success me-2" />
//                 <strong>Adress:</strong> 
//                 <a href="https://www.google.com/maps/@59.4088432,17.8398578,3a,75y,178.09h,91.32t/data=!3m6!1e1!3m4!1sWXZmXZzImBeA4ZQyAjcQow!2e0!7i16384!8i8192?entry=ttu" 
//                   target="_blank" rel="noopener noreferrer" 
//                   style={{ textDecoration: 'none', color: 'inherit' }}>
//                   Nettovägen 5, 175 41 Järfälla
//                 </a>
//               </p>
//               <p>
//                 <Telephone className="text-success me-2" />
//                 <a href="tel:+46735658662" className="text-dark text-decoration-none">
//                   <strong>Phone</strong>: 073-565 86 62
//                 </a>
//               </p>
//               <p>
//                 <Envelope className="text-success me-2" />
//                 <a href="mailto:info@spangatradgard.se" className="text-dark text-decoration-none">
//                   <strong>Email</strong>: info@spangatradgard.se
//                 </a>
//               </p>
//             </div>
//             <div style={{ overflow: 'hidden', paddingBottom: '50.25%', position: 'relative', height: 0 }}>
//               <iframe 
//                 src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2030.4788979657617!2d17.836731876894614!3d59.40840610468876!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465f9f78cb66f5a7%3A0x4088dcdcca6b7c00!2zTmV0dG92w6RnZW4gNSwgMTc1IDQxIErDpHJmw6RsbGE!5e0!3m2!1sen!2sse!4v1710639922064!5m2!1sen!2sse"
//                 style={{ border: 0, height: '100%', width: '100%', position: 'absolute' }}
//                 allowFullScreen=""
//                 loading="lazy"
//                 referrerPolicy="no-referrer-when-downgrade"
//                 title="Location Map"
//               ></iframe>
//             </div>
//           </Col>
//         </Row>
//       </Form>
//     </Container>
//   );
// }

// export default Kontakta;

















































// V-2 with API call Postnummer
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { GeoAlt, Telephone, Envelope } from 'react-bootstrap-icons';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';

function Kontakta() {
  // Initialize form data state
  const [formData, setFormData] = useState({
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    gatuadress: '',
    postnr: '',
    stad: '',
    message: '',
    consentGiven: false,
    recaptchaToken: '',
    service: ''
  });
  
  // State for form validation and feedback messages
  const [validated, setValidated] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [feedbackType, setFeedbackType] = useState('');

  // List of services
  const services = [
    "Trädgårdsskötsel", "Gräsklippning", "Häckklippning", "Trädbeskärning", "Ogräsrensning", "Buskbeskärning", "Höststädning", "Bortforsling av trädgårdsavfall", 
    "Maskinell snöröjning", "Manuell snöröjning", "Maskinell halkbekämpning", "Manuell halkbekämpning", "Sandsopning",
    "Anläggning av nya trädgårdar", "Stenläggning och Stensättning", "Planteringsarbeten", "Bevattningssystem", "Mursättning", "Anlägga gräsmatta",
    "Fruktträdsbeskärning", "Gödsling", "Sandning", "Snöröjning och halkbekämpning",
    "Snickeriarbeten", "Mark- och renhållning", "Trädfällning", "Installation och underhåll av uteplatser och trädgårdsmöbler", "Övriga ..."
  ];

  // Function to fetch city data from the API based on the postal code
  useEffect(() => {
    const fetchCityData = async () => {
      if (formData.postnr.length === 5) {
        try {
          const apiKey = process.env.REACT_APP_POSTNUMMER_APIKEY;
          const response = await axios.get(`https://api.papapi.se/lite/?query=${formData.postnr}&format=json&apikey=${apiKey}`);
          if (response.data.results.length > 0) {
            setFormData(prevFormData => ({ ...prevFormData, stad: response.data.results[0].city }));
          } else {
            setFormData(prevFormData => ({ ...prevFormData, stad: '' }));
          }
        } catch (error) {
          console.error('Error fetching city data:', error);
          setFormData(prevFormData => ({ ...prevFormData, stad: '' }));
        }
      }
    };

    fetchCityData();
  }, [formData.postnr]);
  

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (!form.checkValidity() || !formData.consentGiven || !formData.recaptchaToken) {
      console.error('Form validation failed:', { valid: form.checkValidity(), consent: formData.consentGiven, captcha: formData.recaptchaToken });
      e.stopPropagation();
      setValidated(true);
      setFeedbackMessage('Vänligen se till att alla fält är ifyllda korrekt och att ReCAPTCHA är komplett.');
      setFeedbackType('danger');
      return;
    }

    try {
      console.log('Sending data to server:', formData);
      const response = await fetch('https://backend-spanga.onrender.com/api/contact', {
        // const response = await fetch('http://localhost:3001/api/contact', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setFeedbackMessage('Ditt meddelande har skickats till Spånga Trädgård AB.');
        setFeedbackType('success');
        setFormData({ email: '', firstName: '', lastName: '', phoneNumber: '', gatuadress: '', postnr: '', stad: '', message: '', consentGiven: false, recaptchaToken: '', service: '' });
        setValidated(false);
      } else {
        throw new Error('Serverfel inträffade. Försök igen senare.');
      }
    } catch (error) {
      console.error('Error sending contact form email:', error);
      setFeedbackMessage(error.message || 'Det gick inte att skicka ditt meddelande.');
      setFeedbackType('danger');
    }
  };

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData({ ...formData, [name]: type === 'checkbox' ? checked : value });
  };

  // Handle ReCAPTCHA change
  const onCaptchaChange = (value) => {
    console.log("Captcha value:", value);
    setFormData({ ...formData, recaptchaToken: value });
  };

  return (
    <Container className="my-5 bg-light">
      <h2 className="text-center mb-4 fw-bold">Kontakta Oss</h2>
      <p className="text-center mb-4">Har du frågor? Tveka inte att kontakta oss.</p>
      {feedbackMessage && <Alert variant={feedbackType}>{feedbackMessage}</Alert>}
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>

          <div className="row">
            <Form.Group className="mb-3 col-md-6" controlId="formBasicFirstName">
              <Form.Label>Förnamn<span style={{ color: 'red' }}>*</span></Form.Label>
              <Form.Control
                type="text"
                placeholder="Ditt förnamn"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3 col-md-6" controlId="formBasicLastName">
              <Form.Label>Efternamn<span style={{ color: 'red' }}>*</span></Form.Label>
              <Form.Control
                type="text"
                placeholder="Ditt efternamn"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
              />
            </Form.Group>
            </div>

            <div className='row'>
            <Form.Group className="mb-3 col-md-6" controlId="formBasicEmail">
              <Form.Label>Email<span style={{ color: 'red' }}>*</span></Form.Label>
              <Form.Control
                type="email"
                placeholder="Din email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3 col-md-6" controlId="formBasiccPhoneNumber">
              <Form.Label>Telefonnummer<span style={{ color: 'red' }}>*</span></Form.Label>
              <Form.Control
                type="text"
                placeholder="Ditt Telefonnummer"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                required
              />
            </Form.Group>
            </div>

            <Form.Group className="mb-3" controlId="formBasicGatuadress">
              <Form.Label>Gatuadress<span style={{ color: 'red' }}>*</span></Form.Label>
              <Form.Control
                type="text"
                placeholder="Ditt Gatuadress"
                name="gatuadress"
                value={formData.gatuadress}
                onChange={handleChange}
                required
              />
            </Form.Group>

            {/* Group for Postal Code and City on the same row */}
            <div className="row">
              <Form.Group className="mb-3 col-md-6" controlId="formBasicPostnr">
                <Form.Label>Postnummer<span style={{ color: 'red' }}>*</span></Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ditt Postnummer"
                  name="postnr"
                  value={formData.postnr}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3 col-md-6" controlId="formBasicStad">
                <Form.Label>Stad<span style={{ color: 'red' }}>*</span></Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ditt Stad"
                  name="stad"
                  value={formData.stad}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </div>

            {/* Service dropdown list */}
            <Form.Group className="mb-3" controlId="formBasicService">
              <Form.Label>Välj tjänst</Form.Label>
              <Form.Control as="select" name="service" value={formData.service} onChange={handleChange} required>
                <option value="">Välj en tjänst...</option>
                {services.map((service, index) => (
                  <option key={index} value={service}>{service}</option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicMessage">
              <Form.Label>Meddelande<span style={{ color: 'red' }}>*</span></Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Ditt meddelande"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label="Jag samtycker till Integritetspolicyn."
                name="consentGiven"
                checked={formData.consentGiven}
                onChange={handleChange}
                required
              />
              <a href="/PrivacyPolicy" target="_blank">Läs mer</a>
            </Form.Group>
            
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={onCaptchaChange}
            />
            
            <Button variant="success" type="submit" disabled={!formData.consentGiven || !formData.recaptchaToken}>Skicka</Button>
          </Col>
          
          <Col md={6} sm={12} className='mt-4'>
            {/* Responsive map container */}
            <div>
              <p>
                <GeoAlt className="text-success me-2" />
                <strong>Adress:</strong> 
                <a href="https://www.google.com/maps/@59.4088432,17.8398578,3a,75y,178.09h,91.32t/data=!3m6!1e1!3m4!1sWXZmXZzImBeA4ZQyAjcQow!2e0!7i16384!8i8192?entry=ttu" 
                  target="_blank" rel="noopener noreferrer" 
                  style={{ textDecoration: 'none', color: 'inherit' }}>
                  Nettovägen 5, 175 41 Järfälla
                </a>
              </p>
              <p>
                <Telephone className="text-success me-2" />
                <a href="tel:+46735658662" className="text-dark text-decoration-none">
                  <strong>Phone</strong>: 073-565 86 62
                </a>
              </p>
              <p>
                <Envelope className="text-success me-2" />
                <a href="mailto:info@spangatradgard.se" className="text-dark text-decoration-none">
                  <strong>Email</strong>: info@spangatradgard.se
                </a>
              </p>
            </div>
            <div style={{ overflow: 'hidden', paddingBottom: '50.25%', position: 'relative', height: 0 }}>
              <iframe 
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2030.4788979657617!2d17.836731876894614!3d59.40840610468876!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465f9f78cb66f5a7%3A0x4088dcdcca6b7c00!2zTmV0dG92w6RnZW4gNSwgMTc1IDQxIErDpHJmw6RsbGE!5e0!3m2!1sen!2sse!4v1710639922064!5m2!1sen!2sse"
                style={{ border: 0, height: '100%', width: '100%', position: 'absolute' }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Location Map"
              ></iframe>
            </div>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}

export default Kontakta;
