//Footer.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Facebook, Instagram, Youtube, GeoAlt, Telephone, Envelope } from 'react-bootstrap-icons';

function Footer() {
  return (
    <footer className="footer bg-light text-dark mt-auto py-4">
      <Container>
        <Row className="align-items-center justify-content-between">
          <Col xs={12} md={6} className="text-center text-md-start mb-3 mb-md-0">
            <h5 className="text-uppercase">Kontakta Oss</h5>
            <p>
              <GeoAlt className="text-success me-2" />
              <strong>Adress:</strong> 
              <a href="https://www.google.com/maps/@59.4088432,17.8398578,3a,75y,178.09h,91.32t/data=!3m6!1e1!3m4!1sWXZmXZzImBeA4ZQyAjcQow!2e0!7i16384!8i8192?entry=ttu" 
                target="_blank" rel="noopener noreferrer" 
                style={{ textDecoration: 'none', color: 'inherit' }}>
                Nettovägen 5, 175 41 Järfälla
              </a>
            </p>
            <p>
              <Telephone className="text-success me-2" />
              <strong>Phone:</strong> 
              <a href="tel:+46735658662" className="text-dark text-decoration-none">
                073-565 86 62
              </a>
            </p>
            <p>
              <Envelope className="text-success me-2" />
              <strong>Email:</strong> 
              <a href="mailto:info@spangatradgard.se" className="text-dark text-decoration-none">
                 info@spangatradgard.se
              </a>
            </p>
          </Col>
          <Col xs={12} md={6} className="text-center text-md-end">
            <h5>Följ Oss</h5>
            <a href="https://www.facebook.com/spangatradgardAB" className="text-success mx-2" aria-label="Facebook" target="_blank" rel="noopener noreferrer" >
              <Facebook size={24} />
            </a>
            <a href="https://www.instagram.com/spangatradgard_ab?igsh=MXM3ODMybmZscnowZw==" className="text-success mx-2" aria-label="Instagram" target="_blank" rel="noopener noreferrer">
              <Instagram size={24} />
            </a>
            <a href="https://www.youtube.com/@SpangaTradgard-gu5ws" className="text-success mx-2" aria-label="Youtube" target="_blank" rel="noopener noreferrer">
              <Youtube size={24} />
            </a>
          </Col>
        </Row>
        {/* Design and development by Murtadha */}
        <Row>
          <Col className="text-center mt-3">
            <p>© 2024 Spånga Trädgård AB. Design and development by Murtadha Alobaidi.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;