//Header.js
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { ReactComponent as PersonCircleIcon } from 'bootstrap-icons/icons/person-circle.svg';


const Header = ({ isAuthenticated, logout }) => {
  const location = useLocation();
  const [expanded, setExpanded] = useState(false); // State to manage the Navbar collapse

  // Function to determine if a path matches the current location
  const isActive = (path) => location.pathname === path;

  // Toggle the Navbar manually on link click
  const toggleNavbar = () => setExpanded(!expanded);

  

  // Close the Navbar when a link is clicked
  const closeNavbar = () => setExpanded(false);

  return (
    <Navbar bg="light" expand="lg" className="shadow-sm" expanded={expanded} style={{ position: 'fixed', width: '100%', top: 0, zIndex: 1000 }}>
      <Container>
        <Navbar.Brand as={Link} to="/" onClick={closeNavbar}>
          <img alt="Spånga Trädgård AB" src="/logo5125.png" height="40" className="d-inline-block align-top" />
          {' '}
          <span className="navbar-brand-text text-success" style={{ fontSize: '1.50rem', fontWeight: 'bold'}}>Spånga Trädgård AB</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={toggleNavbar} />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/" className={isActive('/') ? 'active' : ''} onClick={closeNavbar}>Hem</Nav.Link>
            <Nav.Link as={Link} to="/Services" className={isActive('/Services') ? 'active' : ''} onClick={closeNavbar}>Tjänster</Nav.Link>

            <Nav.Link as={Link} to="/Om" className={isActive('/Om') ? 'active' : ''} onClick={closeNavbar}>Om Oss</Nav.Link>
            <Nav.Link as={Link} to="/contact" className={isActive('/contact') ? 'active' : ''} onClick={closeNavbar}>Kontakta Oss</Nav.Link>
            <NavDropdown title="Mer" id="basic-nav-dropdown">
              {/* <NavDropdown.Item as={Link} to="/team" className={isActive('/team') ? 'active' : ''} onClick={closeNavbar}>Vårt Team</NavDropdown.Item> */}
              <NavDropdown.Item as={Link} to="/gallery" className={isActive('/gallery') ? 'active' : ''} onClick={closeNavbar}>Galleri</NavDropdown.Item>
            </NavDropdown>
          </Nav>
         
          <Nav>
            {isAuthenticated && (
              <>
                <Nav.Link as={Link} to="/profile" className={isActive('/profile') ? 'active' : ''} onClick={closeNavbar}>
                  Profile
                </Nav.Link>
                <Nav.Link as={Link} to="/" onClick={() => {logout(); closeNavbar();}}>
                  <PersonCircleIcon /> Logga ut
                </Nav.Link>
              </>
            )}
            {!isAuthenticated && (
              // <Nav.Link as={Link} to="/LoggaIn" className={isActive('/LoggaIn') ? 'active' : ''} onClick={closeNavbar}>
              //   <PersonCircleIcon /> Logga In
              // </Nav.Link>

              <Nav.Link as={Link} to="/Login" className={isActive('/Login') ? 'active' : ''} onClick={closeNavbar}>
              <PersonCircleIcon /> Logga in
              </Nav.Link>
            )}
          </Nav>
          
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;