//Home.js
import React, { useEffect, useState} from 'react';
import { Button, Container, Row, Col, Carousel, Image, Card} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { GiTreeBranch, GiGrass, GiSnowflake1, GiBrickWall, GiPlantSeed } from 'react-icons/gi';
import { IoMdConstruct } from 'react-icons/io';
import { FaHammer, FaTree, FaArrowUp, FaPhoneAlt,FaHome } from 'react-icons/fa';
import Kontakta from './Kontakta.js';


const ServiceItem = ({ icon: Icon, title, description, price, priceDescription }) => {
  // Define which services are not eligible for RUT deduction
  const noRutServices = ["Snickare", "Stenläggning och Plattläggning","Mark och renhållning"];

  // Check if the current service is in the list of non-eligible services
  const isRutAvailable = !noRutServices.includes(title);

  return (
    <Col lg={4} md={6} sm={12} className="mb-4">
      <Card className="h-100 service-card bg-light">
        <Card.Body className="d-flex flex-column">
          <div className="service-icon-container mb-3">
            <Icon size="3em" />
          </div>
          <Card.Title className="text-center mb-3">{title}</Card.Title>
          <Card.Text>{description}</Card.Text>

          {/* Conditional rendering based on RUT deduction eligibility */}
          <p style={{ fontSize: '1.2rem', fontWeight: 'bold', color: '#198754' }}>
            <FaHome className="me-2" />
            {isRutAvailable ? "RUT-avdrag"  : "EJ RUT-avdrag"}
          </p>

          <Button variant="outline-success" as={Link} to="/Services">Läs Mer</Button>
        </Card.Body>
      </Card>
    </Col>
  );
};


// Exempel på kundlogotyper
 const clientLogos = [
   { src: '/images/kundlogotyper/balder.jpeg', alt: 'Kund 1' },
   { src: '/images/kundlogotyper/coor.jpeg', alt: 'Kund 2' },
   { src: '/images/kundlogotyper/peab.jpeg', alt: 'Kund 3' },
   { src: '/images/kundlogotyper/riksbyggen-1.png', alt: 'Kund 4' },
   { src: '/images/kundlogotyper/svenskaMarkservice.jpeg', alt: 'Kund 5' },
   // Lägg till fler logotyper som behövs
 ];

function Home() {
  //backround images
    const carouselItems = [
    {src: "/images/grasrullar.png",title: "Trädgårdsskötsel",description:"Skötsel & Underhåll"},
    {src: "/images/stensattning.png",title: "Stensättning",description: "Vackert Utemiljö & Hållbart"},
    {src: "/images/plattsattning.png",title: "Plattsättning",description: "Gångvägar & Uteplatser"},
    {src: "/images/mursattning.png",title: "Mursättning",description:"Trädgårdsmurar & Stödmurar"},
    {src: "/images/tradfallning.png",title: "Trädfällning",description: "Säker & Effektiv"}
  ];

//Servuce
//, price: "350", priceDescription: "*Inklusive moms samt efter RUT-avdrag (RUT-avdraget är 50%) Minimum 2 h per tillfälle."
  const services = [
    { icon: GiTreeBranch, title: "Trädskötsel", description: "Professionell vård och underhåll av träd." },
    { icon: GiGrass, title: "Anläggning", description: "Omfattande design och konstruktion av grönområden." },
    { icon: IoMdConstruct, title: "Mark och renhållning", description: "Underhåll av markytor för trivsamma offentliga och privata utrymmen." },
    { icon: GiSnowflake1, title: "Vinterunderhåll", description: "Snöröjning och halkbekämpning för säkra vägar och gångvägar." },
    { icon: GiPlantSeed, title: "Planteringsarbeten", description: "Val och plantering av växter för en levande trädgård." },
    { icon: FaTree, title: "Trädfällning", description: "Säker och effektiv borttagning av träd från din fastighet." },
    { icon: GiBrickWall, title: "Stenläggning och Plattläggning", description: "Skapande av hållbara och estetiska ytor." },
    { icon: FaHammer, title: "Snickare", description: "Allt från reparationer till nya konstruktioner." }
  ];
    
    // // för gallery
    // // Exempelbildsdata
     const imageData = [
       //   { url: '/images/grasrullar.png', alt: 'Bild 1' },
       //   { url: '/images/stensattning.png', alt: 'Bild 2' },
         { url: '/images/services/plantering.jpg', alt: 'Bild 3' },
         { url: '/images/services/landscaping.jpg', alt: 'Bild 4' },
         { url: '/images/services/sandsopning.jpg', alt: 'Bild 5' },
         { url: '/images/services/snickare.jpg', alt: 'Bild 6' },
         { url: '/images/services/stensattning.jpg', alt: 'Bild 7' },
         { url: '/images/services/tradskotsel.jpg', alt: 'Bild 8' },
         { url: '/images/services/treeservice.jpg', alt: 'Bild 9' },
         { url: '/images/services/vinterunderhall.jpg', alt: 'Bild 10' },
      
       ];

       const [selectedImageIndex, setSelectedImageIndex] = useState(0);

       useEffect(() => {
         const timer = setInterval(() => {
           setSelectedImageIndex((prevIndex) => (prevIndex + 1) % imageData.length); // Loopa genom bilderna
         }, 3000); // Ändra bild var 3:e sekund
      
         return () => clearInterval(timer); // Rensa timer när komponenten avmonteras
       }, [imageData.length]); // Lägg till imageData.length i beroendelistan


       //"scroll to top" button
       const [showScroll, setShowScroll] = useState(false);
       useEffect(() => {
        const checkScrollTop = () => {
          if (!showScroll && window.pageYOffset > 400) {
            setShowScroll(true);
          } else if (showScroll && window.pageYOffset <= 400) {
            setShowScroll(false);
          }
        };
    
        window.addEventListener('scroll', checkScrollTop);
        return () => {
          window.removeEventListener('scroll', checkScrollTop);
        };
      }, [showScroll]);  // 'showScroll' is the only dependency needed here
    
      const scrollTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };


  return (
    <div className="Home">
    {/* image backround */}
    <Carousel interval={3000} fade={true}>
        {carouselItems.map((item, index) => (
        <Carousel.Item key={index}>
            <img className="d-block w-100" src={item.src} alt={item.title} />
            <Carousel.Caption className="bg-success opacity-75 rounded">
            <h1 className='text-white fw-bold'>{item.title}</h1>
            <p><h5 className='text-white fw-bold'>{item.description}</h5></p>
            </Carousel.Caption>
        </Carousel.Item>
        ))}
    </Carousel>

    {/* Knappen som navigerar till Kontakt-sektionen */}
    <Container className="text-center my-4">
        <Button variant="success" href="#contactSection" style={{ fontWeight: 'bold' }}>Kontakta Oss</Button>
    </Container>

    
    {/* //Våra Tjänster */}
      <Container className="my-5">
        <h2 className="text-center mb-4">Våra Tjänster</h2>
        <Row>
          {services.map((service, index) => (
            <ServiceItem key={index} {...service}/>
          ))}
        </Row>
      </Container>


    {/* Knappen som navigerar till Kontakt-sektionen */}
    <Container className="text-center my-5 p-5 contact-section bg-light">
      <Row className="align-items-center">
        <Col md={6} className="mb-4 mb-md-0">
          <Image 
            src="/rut-avdrag-logo.png" 
            alt="RUT Deduction Info" 
            fluid 
            rounded 
            style={{ maxWidth: '300px' }} // Adjust this value as needed
          />
        </Col>
        <Col md={6}>
          <h2>Kontakta Oss</h2>
          <p>Har du frågor? Tveka inte att kontakta oss eller använda våra tjänster med fördelen av RUT-avdraget.
            <br />
            Du kan läsa mer om RUT-avdrag på Skatteverkets hemsida&nbsp; 
            <a href="https://skatteverket.se/foretag/skatterochavdrag/rotochrut/gerarbetetratttillrutavdrag.4.2ef18e6a125660db8b080001531.html" target="_blank" rel="noopener noreferrer">
               här
            </a>.
          </p>

          {/* <p>Har du frågor? Tveka inte att kontakta oss eller använda våra tjänster med fördelen av RUT-avdraget.</p> */}
          <p style={{ fontSize: '1.2rem', fontWeight: 'bold', color: '#198754' }}>RUT-avdraget är 50% betalat.</p>
          <Button variant="success" size="lg" href="tel:+46735658662" style={{ fontWeight: 'bold' }}>
            <FaPhoneAlt className="me-2" />Boka en Tjänst
          </Button>
        </Col>
      </Row>
    </Container>
          
    {/* // Kontakta oss */}
    {/* Contact Us Section */}
    <Container id="contactSection" className="my-5 bg-light">
      <Kontakta/>
    </Container>

    {/* About Us Section */}{/* Om Oss Sektionen med mer innehåll */}
      <Container className="my-5 bg-light">
        <h2 className="text-center mb-4 fw-bold">Om Oss</h2>
        <Row>
          <Col md={6}>
            <Image  src="/logo5125.png" height="70" className="mb-4" />

            <p>Spånga Trädgård AB grundades 2012 och är ett familjeägt trädgårdsföretag som specialiserar sig på att skapa och underhålla vackra utomhusmiljöer. Vi erbjuder allt från anläggningsarbeten till trädfällning och snöskottning, med ett engagemang för hållbarhet och kvalitet.</p>
            <p>Vårt team består av erfarna specialister som brinner för att förvandla varje utrymme till en grönskande oas. Med en djup respekt för natur och miljö, strävar vi efter att leverera tjänster som inte bara uppfyller, utan överträffar våra kunders förväntningar.</p>
          </Col>
          <Col md={6}>
            <h4>Vår Mission</h4>
            <p>Att förbättra livskvaliteten för våra kunder genom att erbjuda enastående trädgårdstjänster som skapar harmoniska och miljömässigt hållbara utomhusmiljöer.</p>
            <h4>Våra Värderingar</h4>
            <ul>
              <li>Hållbarhet</li>
              <li>Precision</li>
              <li>Kundnöjdhet</li>
            </ul>
            <Button variant="success" as={Link} to="/Om" className="mt-3">Läs Mer</Button>
          </Col>
        </Row>
      </Container>

    {/* Knappen som navigerar till Kontakt-sektionen */}
    <Container className="text-center my-4">
        <Button variant="success" href="#contactSection" style={{ fontWeight: 'bold' }}>Kontakta Oss</Button>
    </Container>

    {/* Testimonials Section (Referenser) */}
      <Container className="my-5">
          <h2 className="text-center mb-4 fw-bold">Kunder och Referenser</h2>
          <Row className="justify-content-center">
              <Col md={4}>
              <Card className='bg-light'>
                  <Card.Body>
                  <blockquote className="blockquote mb-0 ">
                      <p>"Fantastiskt arbete! Vår trädgård har aldrig sett bättre ut."</p>
                      <footer className="blockquote-footer">Anna Svensson</footer>
                  </blockquote>
                  </Card.Body>
              </Card>
              </Col>
              <Col md={4}>
              <Card className='bg-light'>
                  <Card.Body>
                  <blockquote className="blockquote mb-0">
                      <p>"Väldigt duktiga grabbar som vet hur man jobbar och ger bra service"</p>
                      <footer className="blockquote-footer">David Lindgren</footer>
                  </blockquote>
                  </Card.Body>
              </Card>
              </Col>
              <Col md={4}>
              <Card className='bg-light'>
                  <Card.Body>
                  <blockquote className="blockquote mb-0">
                      <p>"Fantastiskt arbete! Vår trädgård har aldrig sett bättre ut."</p>
                      <footer className="blockquote-footer">Anna Svensson</footer>
                  </blockquote>
                  </Card.Body>
              </Card>
              </Col>
          </Row>
       </Container>


    {/* Client Logos Section Test-1 mellan*/}
        <Container className="my-5">
            <h2 className="text-center mb-4 fw-bold">Våra Större Kunder</h2>
            <Row className="justify-content-center">
                {clientLogos.map((logo, index) => (
                    <Col xs={6} sm={4} md={3} lg={2} className="mb-3 d-flex justify-content-center align-items-center">
                        <Image src={logo.src} alt={logo.alt} fluid className="p-3" style={{ maxHeight: '100px' }}/>
                    </Col>
                ))}
            </Row>
        </Container>


     {/* Gallery Section */}
      <Container className="my-5 text-center">      
        {/* {<Gallery /> } */}
        <Container fluid="lg">
          <Row >
            <Col >
              <img src={imageData[selectedImageIndex].url} alt={imageData[selectedImageIndex].alt} className="img-fluid img-thumbnail" style={{ width: '70%' }}/>
            </Col>
          </Row>
        </Container>
      </Container>
        
    {/* Knappen som navigerar till Kontakt-sektionen */}
    <Container className="text-center my-4">
        <Button variant="success" href="#contactSection" style={{ fontWeight: 'bold' }}>Kontakta Oss</Button>
    </Container>


   
    {/* "scroll to top" button */}
    <Button 
        className={`scrollTop ${showScroll ? 'show' : ''} btn btn-success` } 
        onClick={scrollTop} 
        style={{ position: 'fixed', bottom: '20px', right: '20px', display: showScroll ? 'flex' : 'none' }}
      >
        <FaArrowUp />
      </Button>



    </div>
  );
}

export default Home;