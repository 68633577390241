import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { FaArrowUp } from 'react-icons/fa';

// ServiceCard Component
const ServiceCard = ({ service }) => (
  <Col md={4} sm={6} className="mb-4">
    <Card className="h-100 shadow">
      <Card.Img variant="top" src={service.imageUrl} alt={service.title} />
      <Card.Body>
        <Card.Title>{service.title}</Card.Title>
        <Card.Text dangerouslySetInnerHTML={{ __html: service.description }}></Card.Text>
        {/* <Button variant="success" href={`/Services/${service.id}`}>KONTAKTA FÖR OFFERT</Button> */}
        <Button variant="success" href={`/contact`}>KONTAKTA FÖR OFFERT</Button>
      </Card.Body>
    </Card>
  </Col>
);

// Services Component
const Services = () => {
  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    const checkScrollTop = () => {
      if (!showScroll && window.pageYOffset > 400){
        setShowScroll(true);
      } else if (showScroll && window.pageYOffset <= 400){
        setShowScroll(false);
      }
    };
    window.addEventListener('scroll', checkScrollTop);
    return () => {
      window.removeEventListener('scroll', checkScrollTop);
    };
  }, [showScroll]);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

// Categories data
const categories = [
  {
    title: "Trädgårdsskötsel",
    services: [
      { id: 1, title: "Gräsklippning", description: "Vi klipper gräset så det ser snyggt och välskött ut året om.<br /><span style='color: green;'>Arbetet ger rätt till RUT-avdraget.</span.</span>", imageUrl: "/images/services/Tradgardskotsel/grasklipning.jpg" },
      { id: 2, title: "Häckklippning", description: "Vi formar och beskär dina häckar för en prydlig utemiljö.<br /><span style='color: green;'>Arbetet ger rätt till RUT-avdraget.</span.</span>", imageUrl: "/images/services/Tradgardskotsel/hackklipning.jpg" },
      { id: 3, title: "Trädbeskärning", description: "Vi beskär träd för att främja tillväxt och hälsa.<br /><span style='color: green;'>Arbetet ger rätt till RUT-avdraget.</span.</span>", imageUrl: "/images/services/Tradgardskotsel/tradbeskarning.jpg" },
      { id: 4, title: "Ogräsrensning", description: "Vi håller din trädgård fri från ogräs för en hälsosam miljö.<br /><span style='color: green;'>Arbetet ger rätt till RUT-avdraget.</span.</span>", imageUrl: "/images/services/Tradgardskotsel/ograstagning.jpg" },
      { id: 5, title: "Buskbeskärning", description: "Regelbunden beskärning av buskar för att behålla form och hälsa.<br /><span style='color: green;'>Arbetet ger rätt till RUT-avdraget.</span.</span>", imageUrl: "/images/services/Tradgardskotsel/buskbeskarning.jpg" },
      { id: 6, title: "Höststädning", description: "Vi förbereder din trädgård för vintern genom omfattande rengöring.", imageUrl: "/images/services/Tradgardskotsel/hoststadning.jpg" },
      { id: 7, title: "Bortforsling av trädgårdsavfall", description: "Effektiv bortforsling av trädgårdsavfall för att hålla din trädgård ren.", imageUrl: "/images/services/Bortforsling.jpg" },
      { id: 8, title: "Övrig trädgårdsarbete", description: "Allt från plantering till underhåll av trädgårdsmöbler.", imageUrl: "/images/services/Tradgardskotsel/ovrigt_tradgardsarbete.jpg" }
    ]
  },
  {
    title: "Vinterunderhåll",
    services: [
      { id: 9, title: "Maskinell snöröjning", description: "Effektiv snöröjning med tunga maskiner för stora ytor.", imageUrl: "/images/services/vinterunderhall.jpg" },
      { id: 10, title: "Manuell snöröjning", description: "Manuell snöröjning för trånga och känsliga områden.", imageUrl: "/images/services/Vinterunderhall/manuell_snorojning.jpg" },
      { id: 11, title: "Maskinell halkbekämpning", description: "Automatiserad spridning av halkbekämpande medel.", imageUrl: "/images/services/Vinterunderhall/maskinell_halkbekampning.png" },
      { id: 12, title: "Manuell halkbekämpning", description: "Målriktad halkbekämpning för specifika ytor.", imageUrl: "/images/services/Vinterunderhall/manuell_halkbekampning.png" },
      { id: 13, title: "Sandsopning", description: "Borttagning av sand och grus efter vintern.", imageUrl: "/images/services/sandsopning.jpg" }
    ]
  },
  {
    title: "Anläggningsarbeten",
    services: [
      { id: 14, title: "Anläggning av nya trädgårdar", description: "Vi anlägger nya trädgårdar och grönområden.", imageUrl: "/images/services/anlaggning.jpg" },
      { id: 15, title: "Stenläggning och Stensättning", description: "Installation av hållbara och estetiskt tilltalande stenarbeten.", imageUrl: "/images/services/stensattning.jpg" },
      { id: 16, title: "Planteringsarbeten", description: "Professionell plantering som berikar din trädgård med liv.", imageUrl: "/images/services/plantering.jpg" },
      { id: 17, title: "Bevattningssystem", description: "Installation och underhåll av effektiva bevattningssystem.", imageUrl: "/images/services/bevattning.png" },
      { id: 18, title: "Mursättning", description: "Professionell mursättning för stödmurar och trädgårdsdekorationer som förbättrar både funktionalitet och estetik i din utomhusmiljö.", imageUrl: "/images/mursattning.jpg" },
      { id: 19, title: "Anlägga gräsmatta", description: "Vi lägger vackra och hållbara gräsmattor som fräschar upp din trädgård.", imageUrl: "/images/grasrullar.jpg" }
    ]
  },
  {
    title: "Säsongstjänster",
    services: [
      { id: 20, title: "Fruktträdsbeskärning", description: "Beskärning av fruktträd för bättre skörd och hälsa.", imageUrl: "/images/services/Tradgardskotsel/tradbeskarning.jpg" },
      { id: 21, title: "Gödsling", description: "Tillför näring till din trädgård för optimal tillväxt.", imageUrl: "/images/services/godsla.png" },
      { id: 22, title: "Sandning", description: "Sandning för att förbättra greppet under isiga förhållanden.", imageUrl: "/images/services/Vinterunderhall/maskinell_halkbekampning.png" },
      { id: 23, title: "Snöröjning och halkbekämpning", description: "Vinterservice för att hålla gångvägar och uppfarter säkra.", imageUrl: "/images/services/vinterunderhall.jpg" }
    ]
  },
  {
    title: "Specialtjänster",
    services: [
      { id: 24, title: "Snickeriarbeten", description: "Allt från små reparationer till större träbyggnadsprojekt.", imageUrl: "/images/services/snickare.jpg" },
      { id: 25, title: "Mark- och renhållning", description: "Håller din fastighet ren och välorganiserad.", imageUrl: "/images/services/tradskotsel.jpg" },
      { id: 26, title: "Trädfällning", description: "Säker och effektiv borttagning av träd från din fastighet.", imageUrl: "/images/tradfallning.jpg" },
      { id: 27, title: "Installation och underhåll av uteplatser och trädgårdsmöbler", description: "Professionell hjälp för din utemiljö.", imageUrl: "/images/services/utemiljo.jpg" }
    ]
  }
];

  return (
    <Container className="my-5">
      <h2 className="text-center mb-4">Våra Tjänster</h2>
      {categories.map(category => (
        <React.Fragment key={category.title}>
          <h3 className="text-center mb-4">{category.title}</h3>
          <Row>
            {category.services.map(service => (
              <ServiceCard key={service.id} service={service} />
            ))}
          </Row>
        </React.Fragment>
      ))}
      {/* "Scroll to Top" button */}
      <Button 
        className={`scrollTop ${showScroll ? 'show' : ''} btn btn-success`}
        onClick={scrollTop} 
        style={{ position: 'fixed', bottom: '20px', right: '20px', display: showScroll ? 'flex' : 'none' }}
      >
        <FaArrowUp />
      </Button>
    </Container>
  );
};

export default Services;
