// // App.js
import React, { useState, useEffect } from 'react';
import './App.css';
import Header from './Header.js';
import Home from './Home.js';
import Footer from './Footer.js';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import AboutPage from './AboutPage.js';
import Kontakta from './Kontakta.js';
import Services from './Services.js';
import UserProfile from './UserProfile.js';
import ForgotPasswordForm from './ForgotPasswordForm.js';
import ResetPasswordForm from './PasswordResetForm.js';
import Gallery from './Gallery.js';
import Login from './Login.js';
import Signup from './Signup.js';
import PrivacyPolicy from './PrivacyPolicy.js';
// import Billing from './Billing.js';
// import Security from './Security.js';
import axios from 'axios';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthChecked, setIsAuthChecked] = useState(false); // New state for tracking authentication check
  const navigate = useNavigate();

  useEffect(() => {
    const checkToken = async () => {
      const token = sessionStorage.getItem('token');
      if (token) {
        try {
          const response = await axios.post('https://backend-spanga.onrender.com/api/verifyToken', { token });
          // const response = await axios.post('http://localhost:3001/api/verifyToken', { token });

          if (response.status === 200) {
            setIsAuthenticated(true);
          } else {
            setIsAuthenticated(false);
          }
        } catch (error) {
          setIsAuthenticated(false);
        }
      }
      setIsAuthChecked(true); // Set to true after the authentication check is done
    };
    checkToken();
  }, []);

  const loginHandle = (userName, token, userId) => {
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('userName', userName);
    sessionStorage.setItem('userId', userId);

    setIsAuthenticated(true);
    navigate('/profile');
  };

  const logout = () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userName');
    sessionStorage.removeItem('userId');
    setIsAuthenticated(false);
    navigate('/');
  };

  if (!isAuthChecked) {
    return <div>Loading...</div>; // Show loading indicator while authentication check is in progress
  }

  return (
    <div className="App">
      <Header isAuthenticated={isAuthenticated} logout={logout} />
      <div style={{ marginTop: '70px' }}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login onLogin={loginHandle} />} />
          <Route path="/profile" element={isAuthenticated ? <UserProfile userName={sessionStorage.getItem('userName')} userId={sessionStorage.getItem('userId')} /> : <Navigate replace to="/login" />} />
          {/* <Route path="/billing" element={isAuthenticated ? <Billing userName={sessionStorage.getItem('userName')} userId={sessionStorage.getItem('userId')} /> : <Navigate replace to="/login" />} /> */}
          {/* <Route path="/security" element={isAuthenticated ? <Security userName={sessionStorage.getItem('userName')} userId={sessionStorage.getItem('userId')} /> : <Navigate replace to="/login" />} /> */}
          <Route path="/services" element={<Services />} />
          <Route path="/om" element={<AboutPage />} />
          <Route path="/contact" element={<Kontakta />} />
          <Route path="/forgot-password" element={<ForgotPasswordForm />} />
          <Route path="/reset-password" element={<ResetPasswordForm />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
