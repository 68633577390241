//PrivacyPolicy.js
import React, { useState, useEffect, useCallback } from 'react';
import { Container, Button } from 'react-bootstrap';
import { FaArrowUp } from 'react-icons/fa';

const PrivacyPolicy = () => {
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = useCallback(() => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  }, [showScroll]);

  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop);
    return () => {
      window.removeEventListener('scroll', checkScrollTop);
    };
  }, [checkScrollTop]);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Container className="my-5">   
      <h1>Integritetspolicy</h1>
      <p>Denna integritetspolicy förklarar hur Spånga Trädgård AB hanterar personuppgifter i enlighet med EU:s dataskyddsförordning (GDPR).</p>
      <h2>Definition av personuppgifter</h2>
      <p>Personuppgifter inkluderar all information som direkt eller indirekt kan identifiera en person, såsom namn, adress, e-postadress och telefonnummer.</p>
      <h2>Insamling och användning av personuppgifter</h2>
      <p>Vi samlar in personuppgifter för att kunna leverera våra tjänster, hantera vår kundrelation och för marknadsföringsändamål. Detta inkluderar:</p>
      <ul>
        <li>Kontaktuppgifter för att kunna kommunicera med dig.</li>
        <li>Adressinformation för att kunna leverera tjänster till rätt plats.</li>
        <li>Personnummer när det krävs för lagliga syften eller för att ansöka om RUT-avdrag.</li>
      </ul>
      <h2>Varför och hur vi behandlar dina personuppgifter</h2>
      <p>Dina personuppgifter behandlas för att fullgöra avtal, uppfylla juridiska förpliktelser och på grundval av vårt legitima intresse att marknadsföra våra tjänster.</p>
      <h2>Laglig grund</h2>
      <p>Behandlingen av dina personuppgifter baseras på artikel 6 i GDPR. Vi ser till att all behandling är laglig, rättvis och transparent.</p>
      <h2>Dela av personuppgifter</h2>
      <p>Personuppgifter kan delas med tredje parter endast när det är nödvändigt för att utföra våra tjänster, exempelvis med Skatteverket för RUT-avdrag. Vi delar inte dina uppgifter för andra ändamål utan ditt uttryckliga samtycke.</p>
      <h2>Säkerhet</h2>
      <p>Vi vidtar tekniska och organisatoriska åtgärder för att skydda dina uppgifter från obehörig åtkomst, förlust och förstörelse.</p>
      <h2>Dina rättigheter</h2>
      <p>Du har rätt att begära tillgång till, rättelse eller radering av dina personuppgifter. Du har även rätt att motsätta dig behandling och att begära begränsning av behandlingen.</p>
      <h2>Cookies</h2>
      <p>Vi använder cookies för att förbättra din upplevelse på vår webbplats. Nödvändiga cookies kräver inte samtycke, men för andra cookies ber vi om ditt godkännande.</p>
      <h2>Kontaktinformation</h2>
      <p>Om du har frågor om vår integritetspolicy eller behandlingen av dina personuppgifter, är du välkommen att kontakta oss via e-post på info@spangatradgard.se eller per telefon på 073-565 86 62.</p>
      
      <Button 
        className={`scrollTop ${showScroll ? 'show' : ''} btn btn-success`}
        onClick={scrollTop} 
        style={{ position: 'fixed', bottom: '20px', right: '20px', display: showScroll ? 'flex' : 'none' }}
      >
        <FaArrowUp />
      </Button>
    </Container>
  );
};

export default PrivacyPolicy;
